import Localbase from "localbase";

export class Preferences {
	// This class uses localStorage for preferences
	static _KEY_PREFIX = process.env.VUE_APP_NAME;
	static _LOG_PREFIX = "[Preferences]";
	static _ENABLE_LOGGING = false;

	static _log(...args) {
		if (this._ENABLE_LOGGING) console.log(this._LOG_PREFIX, ...args);
	}

	static _getRealKey(clientKey) {
		return `${this._KEY_PREFIX}__${clientKey}`;
	}

	/**
	 * Saves a preference value
	 *
	 * @param {string} key
	 * @param {any} value Any serializable value. If it is not JSON-serializable, retrieved item
	 * might not be the same.
	 */
	static set(key, value) {
		const realKey = this._getRealKey(key);
		const serializedValue = JSON.stringify(value);
		this._log("Saving:", key, serializedValue);
		localStorage.setItem(realKey, serializedValue);
	}

	/**
	 * Gets a preference value
	 *
	 * @param {string} key Preference name
	 */
	static get(key) {
		const realKey = this._getRealKey(key);
		const value = JSON.parse(localStorage.getItem(realKey));
		this._log("Loaded:", key, value);
		return value;
	}

	static delete(key) {
		const realKey = this._getRealKey(key);
		localStorage.removeItem(realKey);
	}
}

export class HelferPreferences extends Preferences {
	static orderEditorSavedFields = {
		_key: "order-editor.saved-fields",
		/** @returns {{[key: string]: any}} */
		get: () => this.get(this.orderEditorSavedFields._key),
		set: fields => this.set(this.orderEditorSavedFields._key, fields)
	};

	static orderEditorRecovery = {
		_key: "order-editor.recovery",
		get: () => this.get(this.orderEditorRecovery._key),
		set: fields => this.set(this.orderEditorRecovery._key, fields),
		delete: () => this.delete(this.orderEditorRecovery._key)
	};

	static savedCredentials = {
		_key: "auth.saved-credentials",
		/** @returns {[string, string]} */
		get: () => {
			const saved = this.get(this.savedCredentials._key);
			if (saved) return [saved.userId, saved.password, saved.username];
			else return [undefined, undefined, undefined];
		},
		set: (userId, password, username) =>
			this.set(this.savedCredentials._key, { userId, password, username })
	};

	static savedLists = {
		_key: "lists.saved-lists",
		get: user => {
			const current = this.get(this.savedLists._key) || {};
			return current[user];
		},
		set: (user, lists) => {
			const current = this.get(this.savedLists._key) || {};
			current[user] = lists;
			this.set(this.savedLists._key, current);
		}
	};
}

export class AdminPreferences extends Preferences {
	static searchItemsPerPage = {
		_key: "search.items-per-page",
		get: () => this.get(this.searchItemsPerPage._key),
		set: val => this.set(this.searchItemsPerPage._key, val)
	};
}

/**
 * Persists objects on the device, organized in collections
 */
export class LocalObjectStore {
	static _STORE_NAME = process.env.VUE_APP_NAME + "_object_store";
	static _STORE = new Localbase(this._STORE_NAME);
	static _LOG_PREFIX = `[${this.constructor.name}]`;

	static _log(...args) {
		console.log(this._LOG_PREFIX, ...args);
	}
	static _throw(msg) {
		throw `${this._LOG_PREFIX} ${msg}`;
	}

	/**
	 * Stores a single new document in the collection
	 *
	 * @param {string} collection
	 * @param {any} document
	 */
	static async store(collection, document) {
		return await this._STORE.collection(collection).add(document);
	}

	/**
	 * Gets the contents of a collection
	 *
	 * @param {string} collection
	 * @param {string} orderBy What key to order results by
	 * @param {"asc"|"desc"} dir Whether to sort ascending or descending
	 */
	static async getCollection(collection, orderBy = undefined, orderDir = "asc") {
		const collectionInstance = this._STORE.collection(collection);
		if (orderBy) {
			return await collectionInstance.orderBy(orderBy, orderDir).get();
		} else {
			return await collectionInstance.get();
		}
	}

	/**
	 * Finds a document matching given criteria
	 *
	 * @param {string} collection
	 * @param {object} searchCriteria A map of key: value to match
	 * @param {string} orderBy What key to order results by
	 * @param {"asc"|"desc"} dir Whether to sort ascending or descending
	 */
	static async find(collection, searchCriteria, orderBy = undefined, orderDir = "asc") {
		if (orderBy) {
			return await this._STORE
				.collection(collection)
				.orderBy(orderBy, orderDir)
				.doc(searchCriteria)
				.get();
		} else {
			return await this._STORE.collection(collection).doc(searchCriteria).get();
		}
	}

	/**
	 * Updates all documents matching `matchCriteria` in the specified collection with `updateData`.
	 * Fields not present in `updateData` will stay untouched
	 *
	 * @param {string} collection
	 * @param {object} matchCriteria
	 * @param {object} updateData
	 */
	static async update(collection, matchCriteria, updateData) {
		return await this._STORE.collection(collection).doc(matchCriteria).update(updateData);
	}

	/**
	 * Replaces all documents matching `matchCriteria` in the specified collection with `replaceData`.
	 * Fields not present in `replaceData` will be discarded
	 *
	 * @param {string} collection
	 * @param {object} matchCriteria
	 * @param {object} replaceData
	 */
	static async replace(collection, matchCriteria, replaceData) {
		return await this._STORE.collection(collection).doc(matchCriteria).update(replaceData);
	}

	/**
	 * Overwrites an entire collection with an array of new documents
	 *
	 * @param {string} collection
	 * @param {any[]} newContents
	 */
	static async replaceCollection(collection, newContents) {
		return await this._STORE.collection(collection).set(newContents);
	}

	/**
	 * Deletes all documents matching `matchCriteria`
	 *
	 * @param {string} collection
	 * @param {object} matchCriteria
	 */
	static async delete(collection, matchCriteria) {
		// First delete
		const res = await this._STORE.collection(collection).doc(matchCriteria).delete();
		// Then check if collection is empty
		const docs = await this.getCollection(collection);
		if (!docs || docs.length === 0) {
			// Delete collection, new one will be created automatically if needed
			await this.deleteCollection(collection);
		}
		return res;
	}

	/**
	 * Deletes an entire collection
	 *
	 * @param {string} collection
	 */
	static async deleteCollection(collection) {
		return await this._STORE.collection(collection).delete();
	}
}

// Disable localbase debugging
LocalObjectStore._STORE.config.debug = false;

/**
 * Gets the name of the collection storing a given order list
 *
 * @param {number} orderListId
 *
 * @returns {string}
 */
export function orderListCollectionName(orderListId) {
	if (!orderListId) {
		console.error("Invalid order list ID:", orderListId);
		return undefined;
	}
	return `order_list_${orderListId}`;
}
