const Admin = () => import(/* webpackChunkName: "group-admin" */ "@/layouts/Admin.vue");
const AdminHome = () => import(/* webpackChunkName: "group-admin" */ "@/views/admin/Home.vue");
const RecordOrders = () =>
	import(/* webpackChunkName: "group-admin" */ "@/views/admin/RecordOrders.vue");
const ViewLists = () => import(/* webpackChunkName: "group-admin" */ "@/views/admin/ViewLists.vue");
const ManageOrderLists = () =>
	import(/* webpackChunkName: "group-admin" */ "@/views/admin/ManageOrderLists.vue");
const ManageGroupPermissions = () =>
	import(/* webpackChunkName: "group-admin" */ "@/views/admin/ManageGroupPermissions.vue");
const SearchOrders = () =>
	import(/* webpackChunkName: "group-admin" */ "@/views/admin/SearchOrders.vue");
const NotFoundAdmin = () =>
	import(/* webpackChunkName: "group-admin" */ "@/views/admin/NotFound.vue");

const Helfer = () => import("@/layouts/Helfer.vue");
const HelferHome = () => import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/Home.vue");
const Lists = () => import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/Lists.vue");
const Orders = () => import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/Orders.vue");
const Order = () => import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/Order.vue");
const NotFoundHelfer = () =>
	import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/NotFound.vue");
const Archive = () => import(/* webpackChunkName: "group-helfer" */ "@/views/helfer/Archive.vue");

export default [
	{
		path: "/",
		name: "root",
		redirect: { name: "helfer-home" }
	},
	{
		path: "/admin",
		component: Admin,
		children: [
			{
				path: "",
				name: "admin-home",
				component: AdminHome
			},
			{
				path: "view-lists",
				name: "admin-view-lists",
				component: ViewLists
			},
			{
				path: "order-lists",
				name: "admin-order-lists",
				component: ManageOrderLists
			},
			{
				path: "order-lists/:id",
				name: "admin-manage-order-list",
				component: SearchOrders
			},
			{
				path: "order-lists/:olid/orders/:oid/:mode",
				name: "admin-manage-order",
				component: RecordOrders
			},
			{
				path: "group-permissions",
				name: "admin-manage-group-permissions",
				component: ManageGroupPermissions
			},
			{
				path: ":catchAll(.*)",
				component: NotFoundAdmin,
				name: "not-found-admin"
			}
		]
	},
	{
		path: "/helfer",
		component: Helfer,
		children: [
			{
				path: "",
				name: "helfer-home",
				component: HelferHome
			},
			{
				path: "listen",
				name: "helfer-lists",
				component: Lists
			},
			{
				path: "listen/:olid",
				name: "helfer-list-orders",
				component: Orders
			},
			{
				path: "listen/:olid/bestellungen/:oid/:mode",
				name: "helfer-order-editor",
				component: Order
			},
			{
				path: "archiv",
				name: "helfer-archive",
				component: Archive
			},
			{
				path: ":catchAll(.*)",
				component: NotFoundHelfer,
				name: "not-found-helfer"
			}
		]
	},
	{
		path: "/:catchAll(.*)",
		redirect: { name: "helfer-home" }
	}
];
