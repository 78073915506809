<template>
	<div id="layout-base" :class="app">
		<router-view class="layout-content"></router-view>
		<footer class="global-footer" :class="app">
			<span class="info">T: {{ buildTime }}</span>
			<span class="info">V: {{ version }}</span>
			<span class="info">M: {{ mode }}</span>
		</footer>
	</div>
</template>

<script>
export default {
	computed: {
		/** @returns {string} */
		buildTime() {
			return process.env.BUILD_TIME;
		},

		/** @returns {string} */
		mode() {
			return process.env.NODE_ENV;
		},

		/** @returns {string} */
		version() {
			return process.env.PACKAGE_VERSION;
		},

		/** @returns {string} */
		app() {
			const app =
				this.$route.path
					.replace(/^\/+|\/+$/g, "")
					.split("/")
					.find(() => true) || "app";
			return app;
		}
	}
};
</script>

<style lang="scss">
@import "@assets/styles/vars.scss";

/* SUPERGLOBAL STYLES */
html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	min-height: 100%;
	background-color: $blue-white;
}

p {
	hyphens: auto;
}

.app-page {
	margin: $margin-desktop;
	width: 100%;
}

@media screen and (max-width: $break-s) {
	.app-page {
		margin: $margin-mobile;
	}
}
</style>

<style lang="scss" scoped>
@import "@assets/styles/vars.scss";

.global-footer {
	background-color: $blue;
	opacity: 0.7;
	color: rgba($white, 0.5);
	text-align: center;
	font-size: 0.8em;
	padding: 2px;
	margin-top: auto;

	.info {
		margin: 0 10px;
	}

	&.helfer {
		color: $blue;
		background-color: transparent;
		font-size: 0.7em;
	}
}

#layout-base {
	font-family: $sans-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	background-color: $white;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.layout-content {
	flex: 1;
}
</style>
