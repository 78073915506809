/**
 * Gets the number of digits until (excluding) pos
 *
 * @param {string} str Input string
 * @param {number} pos Count up to (excluding)
 *
 * @returns {number}
 */
function digitsUntil(str, pos) {
	const matches = str.substring(0, pos).match(/\d|\+/g);
	return matches ? matches.length : null;
}

/**
 * Gets the position of the nth occurrence of a digit in a string
 *
 * @param {string} str
 * @param {number} n
 *
 * @returns {number}
 */
function nthDigit(str, n) {
	for (let i = 0; i <= str.length; i++) {
		if (/\d|\+/.test(str.charAt(i))) {
			n--;
		}
		if (n === 0) {
			return i;
		}
	}
	return str.length;
}

function findNewSelection(oldPosition, oldString, newString) {
	let digitsBeforeOld = digitsUntil(oldString, oldPosition);
	return nthDigit(newString, digitsBeforeOld) + 1;
}

/**
 * Takes a phone number string and formats it to CH format
 *
 * @param {string} input Number to format
 * @param {[number, number]} selection Tuple containing start and end of current selection
 * @returns {[boolean, string]} Tuple containing [valid, formattedOutput]
 */
export function autoFormatPhoneNumber(input, selection) {
	let cleanedInput = input;

	if (!cleanedInput) {
		return [true, null, selection];
	}

	cleanedInput = cleanedInput.trim();
	cleanedInput = cleanedInput.replace(/[^\d+]/g, "");

	const formats = [
		{
			match: /^(00\d*)$/,
			grouping: [4, 2, 3, 2, 2]
		},
		{
			match: /^\+(\d*)$/,
			grouping: [2, 2, 3, 2, 2],
			prefix: "+"
		},
		{
			match: /^(\d*)$/,
			grouping: [3, 3, 2, 2]
		}
	];

	let output = input.substr();
	let valid = false;

	for (const format of formats) {
		const match = cleanedInput.match(format.match);
		if (match) {
			output = "";
			const maxLen = format.grouping.reduce((a, b) => a + b, 0);
			let numbers = match[1].substr(0, maxLen);

			valid = match[1].length === maxLen;

			for (const groupSize of format.grouping) {
				output += numbers.substr(0, groupSize) + " ";
				numbers = numbers.slice(groupSize);
			}

			output = output.trim();

			if (format.prefix) {
				output = format.prefix + output;
			}

			const overflow = match[1].substr(maxLen).replace(/\s/g, "");
			if (overflow) {
				output += " " + overflow;
			}

			break;
		}
	}

	const newSelection = [
		findNewSelection(selection[0], input, output),
		findNewSelection(selection[1], input, output)
	];

	return [valid, output, newSelection];
}

/**
 * Turns a date object into a Swiss German date string
 *
 * @param {Date} date Date to format
 *
 * @returns {string} Formatted date (de-CH, day, month, short year, hours, (24h), minutes)
 */
export function localeDateTimeString(date) {
	const val = new Date(date);
	if (val) {
		return (
			val.toLocaleDateString("de-CH", { year: "2-digit", month: "2-digit", day: "2-digit" }) +
			" " +
			val.toLocaleTimeString("de-CH", { hour: "2-digit", minute: "2-digit" })
		);
	} else {
		return null;
	}
}

/**
 * Zips two arrays
 *
 * @param {any[]} a
 * @param {any[]} b
 *
 * @returns {[any,any][]}
 */
export function zip(a, b) {
	if (!a || !b) return null;
	const res = [],
		alen = a.length,
		blen = b.length;
	for (let i = 0; i < alen && i < blen; i++) {
		res.push([a[i], b[i]]);
	}
	return res;
}

/**
 * Checks whether given value is a non-null array ([...])
 *
 * @param {any} x
 *
 * @returns {boolean}
 */
export function isArray(x) {
	return x !== null && typeof x === "object" && Array.isArray(x);
}

/**
 * Checks whether given value is a non-null object ({...})
 *
 * @param {any} x
 *
 * @returns {boolean}
 */
export function isObject(x) {
	return x !== null && typeof x === "object" && !Array.isArray(x);
}

/**
 * Checks whether x is of basic value type, function type, undefined or null
 *
 * @param {any} x
 *
 * @returns {boolean}
 */
export function isValueType(x) {
	if (x == undefined || x == null) return true;
	const type = typeof x;
	return type !== "object";
}

export function isPrimitive(x) {
	return !(x instanceof Object);
}

/**
 * Returns a comparator function for two values, given strict and deep options
 *
 * @param {boolean} strict Whether to use strict comparison (`===`)
 * @param {boolean} deep Whether to check for deep equality on objects and arrays. Otherwise
 * equality specified by `strict` will be used.
 *
 * @returns {(x: any, y: any) => boolean}
 */
function getComparator(strict, deep) {
	// Works for any value, object or array
	const shallowComparator = strict ? (a, b) => a === b : (a, b) => a == b;
	let objectComparator;
	if (deep) {
		// Assumes `a` and `b` to be objects or arrays, and both of the same type
		objectComparator = (a, b) => {
			if (isArray(a) && isArray(b)) return arrayEquals(a, b, strict, deep);
			else if (isObject(a) && isObject(b)) return objectEquals(a, b, strict, deep);
			else return false;
		};
	} else {
		objectComparator = shallowComparator;
	}

	return (a, b) => {
		// Check equality on value types
		// Types can still differ and one can even be an array in case of weak equality, e.g. [] == 0
		if (isValueType(a) || isValueType(b)) return shallowComparator(a, b);
		// Check equality on array or object types
		return objectComparator(a, b);
	};
}

/**
 * Compares the two values with given strict and deep parameters
 *
 * @param {any} a
 * @param {any} b
 * @param {boolean} strict Whether to use strict comparison
 * @param {boolean} deep Wether do deep-compare arrays and objects
 *
 * @returns {boolean}
 */
export function equal(a, b, strict = false, deep = false) {
	return getComparator(strict, deep)(a, b);
}

/**
 * Returns true if the two arrays are non-null and have the same values
 *
 * @param {any[]} a
 * @param {any[]} b
 * @param {boolean} strict Whether to use strict equality on value types
 * @param {boolean} deep Whether to use deep equality on object and array types
 */
export function arrayEquals(a, b, strict = false, deep = false) {
	// Both must be arrays
	if (!isArray(a) || !isArray(b))
		throw new Error("First two arguments to arrayEquals must be of type array");
	// If lengths differ, they must be different
	if (a.length !== b.length) return false;
	// Check equality on all pairs
	return zip(a, b).reduce((acc, [x, y]) => acc && equal(x, y, strict, deep), true);
}

/**
 * Returns true if a and b are non-null arrays with the same elements disregarding order
 *
 * @param {any[]} a
 * @param {any[]} b
 * @param {boolean} strict Whether to use strict equality
 * @param {boolean} deep Whether to use deep equality for object and array types. Note that order
 * does matter in sub-objects
 *
 * @returns {boolean}
 */
export function arrayContentsEqual(a, b, strict = false, deep = false) {
	// Both must be arrays
	if (!isArray(a) || !isArray(b))
		throw new Error("First two arguments to arrayEquals must be of type array");
	// Check length
	if (a.length !== b.length) return false;
	// Check inclusion on each element of a
	return a.reduce((acc, x) => acc && b.find(y => equal(x, y, strict, deep)) !== undefined, true);
}

/**
 * Returns true if the two objects are non-null and have the same keys and values
 *
 * @param {object} a
 * @param {object} b
 * @param {boolean} strict Whether to use strict equality on value type fields
 * @param {boolean} deep Whether to use deep equality on object and array type fields
 */
export function objectEquals(a, b, strict = false, deep = false) {
	// Both must be objects
	if (!isObject(a) || !isObject(b))
		throw new Error("First two arguments to objectEquals must be of type object");
	// Get key sets of both
	const keysA = Object.keys(a);
	const keysB = Object.keys(b);
	// Check whether a and b have the same keys
	if (!arrayContentsEqual(keysA, keysB, true, false)) return false;
	// Check whether they agree on all values
	return keysA.reduce((acc, k) => acc && equal(a[k], b[k], strict, deep), true);
}

/**
 * Calculates the object difference "source - dest" taking into account keys and their values
 *
 * I.e.: The returned object will contain a key-value pair `k: v` iff `source` contains `k: v` and
 * `dest` does not contain `k: v`.
 *
 * This means, that the returned object will contain `k: source.v` if either `k` is not a key of
 * `dest`, or `k` is a key of `dest` but `source.v` is different from `dest.v`
 *
 * If the two objects are equal, `{}` will be returned. if `dest` is empty, `source` will
 * be returned.
 *
 * @param {object} source Source object
 * @param {object} dest Object to subtract from `source`
 * @param {boolean} strict Whether to use strict equality for value type fields
 * @param {boolean} deep Whether to use deep equality for objects and arrays
 *
 * @returns {object}
 */
export function objectDifference(source, dest, strict = false, deep = false) {
	const res = { ...source };
	const equal = getComparator(strict, deep);
	for (const key in dest)
		if (key in res && equal(dest[key], res[key]))
			// They are the key and value, remove from result
			delete res[key];
	return res;
}

/**
 * Maps an array
 *
 * @param {any[]} arr
 * @param {(v: any, i: number, self: any[])} mapper
 *
 * @returns {any[]}
 */
export function mapArray(arr, mapper) {
	if (!isArray(arr)) return null;
	const l = arr.length,
		res = new Array();
	for (let i = 0; i < l; i++) res[i] = mapper(arr[i], i, res);
	return res;
}

/**
 * Maps an object keys and values to new keys and new values
 *
 * @param {object} obj
 * @param {(k: string, v: any, self: object) => [string, any]} mapper
 *
 * @returns {object}
 */
export function mapObject(obj, mapper) {
	if (!isObject(obj)) return null;
	const res = {};
	for (const key in obj) {
		const [mappedKey, mappedValue] = mapper(key, obj[key], res);
		res[mappedKey] = mappedValue;
	}
	return res;
}

/**
 * Returns a deep copy of the given value
 *
 * @template T Type of source object
 * @param {T} source Object to copy
 * @param {object} parent (Advanced) used to bind functions to new `this`-arg
 *
 * @returns {T} Deep copy of `source`
 */
export function deepCopy(source, parent = undefined) {
	// Case distinction on typeof source
	switch (typeof source) {
		case "bigint":
		case "boolean":
		case "number":
		case "string":
		case "undefined":
			// Value types are passed by value (by copy) per default
			return source;
		case "object":
			if (isArray(source)) return mapArray(source, (x, _idx, self) => deepCopy(x, self));
			if (isObject(source)) return mapObject(source, (k, v, self) => [k, deepCopy(v, self)]);
			return null;
		case "function":
			// Bind function to new this arg, this will be the closest parent object or array
			if (parent) return source.bind(parent);
			// If parent is undefined, just return the function (nothing to copy)
			else return source;
		case "symbol":
			// New symbol using same key
			return Symbol(source.description);
	}
}

/**
 * Splits an address into street and number (possibly with letters, apt. no., floor etc.)
 *
 * If the splitting fails, the whole address will be returned as the street
 *
 * @param {string} address
 *
 * @returns {[string, string]}
 */
export function splitAddress(address) {
	const addrNumberRegex = /\s*(.*?)\s+((\d+)\s*[A-Za-z 0-9,;.\-/]*)/;
	const match = address.match(addrNumberRegex);
	// Check that no information was lost (i.e. the whole string is matched), else return everything in street
	if (match[0] === address) {
		return [match[1], match[2]];
	} else {
		return [address, undefined];
	}
}

/**
 * Computes the minimum edit distance (Levenshtein distance) between two strings a and b
 *
 * @param {string} a
 * @param {string} b
 *
 * @returns {int} lev(a, b)
 */
export function editDistance(a, b) {
	// For all i and j, d[i,j] will hold the Levenshtein distance between the first i characters of
	// a and the first j characters of b
	const m = a.length,
		n = b.length;
	const d = new Array(m + 1);
	for (let i = 0; i <= m; i++) {
		d[i] = new Array(n + 1);
		for (let j = 0; j <= n; j++) {
			d[i][j] = 0;
		}
	}

	// Source prefixes can be transformed into empty string by dropping all characters
	for (let i = 1; i <= m; i++) {
		d[i][0] = i;
	}
	// Target prefixes can be reached from empty source prefix by inserting every character
	for (let j = 1; j <= n; j++) {
		d[0][j] = j;
	}

	for (let j = 1; j <= n; j++) {
		for (let i = 1; i <= m; i++) {
			const subCost = a.charAt(i - 1) == b.charAt(j - 1) ? 0 : 1;
			d[i][j] = Math.min(
				d[i - 1][j] + 1, // Deletion
				d[i][j - 1] + 1, // Insertion
				d[i - 1][j - 1] + subCost // Substitution
			);
		}
	}

	return d[m][n];
}

/**
 * Replaces all accentuated letters in a string with its non-accentuated version (only lower case)
 *
 * @param {string} str
 *
 * @returns {string}
 */
export function replaceAccents(str) {
	return str
		.replace("ä", "a")
		.replace("ö", "o")
		.replace("ü", "u")
		.replace("é", "e")
		.replace("è", "e")
		.replace("à", "a")
		.replace("ë", "e")
		.replace("ç", "c")
		.replace("ñ", "n");
}

/**
 * Returns true if the searchTerm matches name or address (exact)
 *
 * @param {string} searchTerm
 * @param {object} order
 *
 * @returns {boolean}
 */
export function orderMatchesSearch(searchTerm, order) {
	const name = replaceAccents(order.name?.toLowerCase() ?? "");
	const address = replaceAccents(order.address_street?.toLowerCase() ?? "");
	searchTerm = replaceAccents(searchTerm.toLowerCase() ?? "");
	return (
		name.includes(searchTerm) || address.includes(searchTerm)
		// || editDistance(searchTerm, name) < 3 ||
		// editDistance(searchTerm, address) < 3
	);
}

/**
 * Calls the function if it hasn't been called in the specified timeout window (debounce)
 *
 * @param {(...args: any[]) => any} func Function to debounce
 * @param {number} timeout Time amount to wait before calling function
 *
 * @returns {(...args: any[]) => void} Debounced version of passed function
 */
export function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

/**
 * Throttles a function call to be called at most once every timeout milliseconds
 *
 * @param {(...args: any[]) => any} func Function to throttle
 * @param {number} timeout How long to ignore calls after first call
 *
 * @returns {(...args: any[]) => void} Throttled version of passed function
 */
export function throttle(func, timeout = 300) {
	let timer;
	return (...args) => {
		if (!timer) {
			func.apply(this, args);
		}
		clearTimeout(timer);
		timer = setTimeout(() => {
			timer = undefined;
		}, timeout);
	};
}

export function throttleDebounce(func, throttleTimeout = 100, debounceTimeout = 300) {
	let emitTimer;
	let debounceTimer;

	return (...args) => {
		// Call function if not called for more than throttleTimeout millis
		if (!emitTimer) {
			func.apply(this, args);
			emitTimer = setTimeout(() => {
				emitTimer = undefined;
			}, throttleTimeout);
		}
		// Call function with last value if no input for debounceTimeout millis
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => {
			func.apply(this, args);
		}, debounceTimeout);
	};
}
