import { autoFormatPhoneNumber } from "../utils";

export default function swissTelFilter(value) {
	if (!value) {
		return null;
	}
	if (!/^(?:0.*|00.*|\+.*)$/.test(value)) {
		// Assume this is local and add leading zero
		value = "0" + value;
	}
	return autoFormatPhoneNumber(value, [0, 0])[1];
}
