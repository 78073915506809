export const authorIdLsKey = `${process.env.VUE_APP_NAME}.userId`;
export const fieldsLSKey = `${process.env.VUE_APP_NAME}.order-cache`;
export const rememberFieldsLSKey = `${process.env.VUE_APP_NAME}.remember-fields`;
export const loggedInGroupIdLsKey = `${process.env.VUE_APP_NAME}.logged-in-group-id`;
export const savedPasswordLsKey = `${process.env.VUE_APP_NAME}.saved-password`;
export const adminAuthorizedLsKey = `${process.env.VUE_APP_NAME}.admin-authorized`;
export const savedYearLsKey = `${process.env.VUE_APP_NAME}.selected-year`;

export const cityNameMappings = {
	wie: "Wiesendangen",
	els: "Elsau",
	heg: "Hegi",
	spc: "Anderer Ort",
	all: "Alle Orte"
};

export const cityShortNames = {
	wie: "Wisi",
	els: "Elsau",
	heg: "Hegi",
	spc: "Spezial",
	all: "Alle"
};

export const timeNameMappings = {
	time_8_9: "8 bis 9 Uhr",
	time_9_10: "9 bis 10 Uhr",
	time_10_11: "10 bis 11 Uhr",
	all: "Alle Zeiten"
};

export const timeSlotShortNames = {
	time_8_9: "8-9",
	time_9_10: "9-10",
	time_10_11: "10-11",
	all: "Alle"
};

export const STATUS_LOCAL = "LOCAL";
export const STATUS_DRAFT = "DRAFT";
export const STATUS_PUBLISHED = "PUBLISHED";

export const STATUS_CHECK = "CHECK";

export const UPLOAD_TYPE_NEW = "NEW";
export const UPLOAD_TYPE_DUPLICATE = "DUPLICATE";
export const UPLOAD_TYPE_ERROR = "ERROR";

export const CITY_SPC_ID = "spc"; // Needs to be kept consistent with database
