import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
	routes,
	scrollBehavior(to, _from, _savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			};
		} else {
			return { x: 0, y: 0 }; // Ignore savedPosition
		}
	}
});

router.afterEach(() => {
	if (store.getters.helferLoggedIn) store.dispatch("updateHelferInfoFlyout");
});

export default router;
