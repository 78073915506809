import { timeNameMappings, timeSlotShortNames } from "@/consts";
import { cityNameMappings, cityShortNames } from "@/consts";
import swissTelFilter from "./swissTelFilter";

const CITY_NAME_SPC = "spc";

export function cityNameFilter(cityNameId) {
	return cityNameMappings[cityNameId] || "???";
}

export function cityShortNameFilter(cityNameId) {
	return cityShortNames[cityNameId] || "???";
}

export function orderAmountFilter(order) {
	return `${order.amount_large || "–"} / ${order.amount_small || "–"}`;
}

export function longOrderAmountFilter(order) {
	if (!order) return "Keine Mengenangabe";
	return [
		order.amount_large ? order.amount_large + " gross" : undefined,
		order.amount_small ? order.amount_small + " klein" : undefined
	]
		.filter(x => x)
		.join(", ");
}

export function orderAddressFilter(order) {
	if (!order) return "Keine Adressangabe";
	return `${order.address_street} ${order.address_nr}, ${orderCityFilter(order)}`;
}

export function orderAddressShortFilter(order) {
	if (!order) return "–";
	return `${order.address_street} ${order.address_nr}`;
}

export function orderCityFilter(order) {
	return order.address_city === CITY_NAME_SPC
		? order.city_special
		: cityNameMappings[order.address_city];
}

export function orderCityShortFilter(order) {
	return order.address_city === CITY_NAME_SPC
		? order.city_special
		: cityShortNames[order.address_city];
}

export function orderTimeSlotNameFilter(order) {
	return timeNameMappings[order?.time] || "–";
}

export function orderNameFilter(order) {
	return order?.name || "Keine Namensangabe";
}

export function orderTelFilter(order) {
	return swissTelFilter(order.tel) || "–";
}

export function timeSlotNameFilter(timeSlotNameId) {
	return timeNameMappings[timeSlotNameId] || "???";
}

export function timeSlotShortNameFilter(timeSlotNameId) {
	return timeSlotShortNames[timeSlotNameId] || "???";
}

export function paidFilter(paid) {
	return paid ? "Ja" : "Nein";
}

export function statusFilter(status) {
	switch (status) {
		case "DRAFT":
			return "Entwurf";
		case "PUBLISHED":
			return "OK";
		case "CHECK":
			return "Prüfen";
		case "LOCAL":
			return "Lokal";
		case "ALL":
			return "Alle Stati";
		default:
			return "???";
	}
}
