import { savedYearLsKey } from "@/consts";

export const globalAuthor = {
	id: "all",
	username: "Alle Ersteller",
	public: false,
	created_at: new Date().toISOString(),
	updated_at: new Date().toISOString()
};

export const globalCity = { id: "all", name_id: "all", descriptive_name: "Alle Orte", zip: "0000" };

export const globalSector = { id: "all", name_id: "Alle Gebiete", city_id: 0 };

export const globalOrderList = {
	id: "all",
	author: globalAuthor.id,
	author_username: "System",
	city_name: "Alle Orte",
	city_name_id: globalCity.name_id,
	count: 0,
	sector: "Alle Bestellungen",
	year: localStorage.getItem(savedYearLsKey)
};
export const globalStatus = "ALL";

export const globalTimeSlot = {
	id: "all",
	name_id: "all",
	time_from: "08:00:00",
	time_to: "11:00:00"
};
