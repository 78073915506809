import api from "@/api";
import { LocalObjectStore, orderListCollectionName } from "@/persistence";
import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

Vue.use(Vuex);

let closeToastTimeout = null;

export default new Vuex.Store({
	state: {
		online: true,
		backUrl: "/",
		toast: null,
		popup: null,
		appStatusLoading: false,
		author: null,
		helferToast: null,
		helferModal: null,
		helferLoggedInUser: null,
		helferLoading: null,
		helferInfoFlyout: null
	},
	getters: {
		helferLoggedIn(state) {
			return state.helferLoggedInUser != null;
		}
	},
	mutations: {
		setBackUrl(state, value) {
			state.backUrl = value;
		},
		setAppStatusLoading(state, value) {
			state.appStatusLoading = value;
		},
		setAuthor(state, value) {
			state.author = value;
		},
		helferLoginUser(state, value) {
			state.helferLoggedInUser = value;
		},
		helferLogoutUser(state) {
			state.helferLoggedInUser = null;
		},
		helferSetLoading(state, value) {
			state.helferLoading = { active: true, ...value };
		},
		helferSetReady(state) {
			state.helferLoading = null;
		},
		setHelferInfoFlyout(state, value) {
			state.helferInfoFlyout = value;
		}
	},
	actions: {
		openToast({ state, dispatch }, payload) {
			state.toast = payload;
			if (state.toast.duration) {
				closeToastTimeout = setTimeout(() => {
					dispatch("closeToast");
				}, state.toast.duration * 1000);
			}
		},
		closeToast({ state }) {
			state.toast = null;
			clearTimeout(closeToastTimeout);
		},
		openPopup({ state }, payload) {
			state.popup = payload;
		},
		closePopup({ state }) {
			state.popup = null;
		},
		openHelferToast({ state }, payload) {
			state.helferToast = payload;
		},
		closeHelferToast({ state }) {
			state.helferToast = null;
		},
		openHelferModal({ state }, payload) {
			state.helferModal = payload;
		},
		closeHelferModal({ state }) {
			state.helferModal = null;
		},
		async updateHelferInfoFlyout({ commit }) {
			const numLocalOrders = await api
				.getPublicOrderLists()
				.then(res => res.data.map(x => orderListCollectionName(x.id)))
				.then(listNames => Promise.all(listNames.map(x => LocalObjectStore.getCollection(x))))
				.then(documents => documents.reduce((acc, cur) => acc.concat(cur), []).length)
				.catch(err => {
					if (err.response.code === 401) {
						console.log("Tried accessing public order lists but not logged in.");
					}
				});

			const text = `Du hast ${numLocalOrders} lokale Bestellung(en). Lade diese mit dem "Upload"-Button hoch, sobald du eine gute Internetverbindung hast.`;
			commit("setHelferInfoFlyout", numLocalOrders > 0 ? text : null);
		},
		helferShowOfflineModal({ dispatch }, payload = {}) {
			dispatch("openHelferModal", {
				title: "Offline",
				buttons: [
					{
						text: "Neu laden (Internet nötig)",
						onClick: () => {
							router.go(); // Reloads the page
						},
						class: "success"
					},
					{
						text: "Offline fortfahren",
						onClick:
							payload.handler ||
							(() => {
								router.push({ name: "helfer-lists" });
							}),
						class: "dark"
					}
				],
				buttonsLayout: "stacked",
				content:
					payload.text ||
					"Daten konnten nicht geladen werden. Verbinde dich mit dem Internet und versuche es erneut."
			});
		}
	},
	modules: {}
});
