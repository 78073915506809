import "./registerServiceWorker";

import Vue from "vue";
import router from "./router";

import App from "./App.vue";
import swissTelFilter from "./filters/swissTelFilter";
import store from "./store";

import {
	cityNameFilter,
	cityShortNameFilter,
	longOrderAmountFilter,
	orderAddressFilter,
	orderAmountFilter,
	orderNameFilter,
	orderTimeSlotNameFilter,
	orderCityFilter,
	paidFilter,
	statusFilter,
	timeSlotNameFilter,
	timeSlotShortNameFilter,
	orderTelFilter,
	orderAddressShortFilter,
	orderCityShortFilter
} from "./filters/orderFilters";

import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.filter("swissTel", swissTelFilter);
Vue.filter("city", cityNameFilter);
Vue.filter("cityShort", cityShortNameFilter);
Vue.filter("timeSlot", timeSlotNameFilter);
Vue.filter("timeSlotShort", timeSlotShortNameFilter);
Vue.filter("orderAmount", orderAmountFilter);
Vue.filter("longOrderAmount", longOrderAmountFilter);
Vue.filter("orderAddress", orderAddressFilter);
Vue.filter("orderTimeSlot", orderTimeSlotNameFilter);
Vue.filter("orderName", orderNameFilter);
Vue.filter("orderCity", orderCityFilter);
Vue.filter("paid", paidFilter);
Vue.filter("status", statusFilter);
Vue.filter("orderTel", orderTelFilter);
Vue.filter("orderAddressShort", orderAddressShortFilter);
Vue.filter("orderCityShort", orderCityShortFilter);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
