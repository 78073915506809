import axiosLib from "axios";
// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";
import {
	savedYearLsKey,
	UPLOAD_TYPE_DUPLICATE,
	UPLOAD_TYPE_ERROR,
	UPLOAD_TYPE_NEW
} from "./consts";
import store from "@/store";
import { globalAuthor, globalOrderList } from "./assets/data/admin/search/globals";

const axios = axiosLib;

const cacheFirst = axiosLib.create({
	adapter: cacheAdapterEnhancer(axiosLib.defaults.adapter)
});

const year = localStorage.getItem(savedYearLsKey) || new Date().getFullYear();

const status = {
	online: true,
	changed: false
};

const statusChangeHandlers = {};

// Offline notifications
window.addEventListener("load", () => {
	function handleNetworkChange(_event) {
		store.state.online = navigator.onLine;
		if (navigator.onLine) {
			document.body.classList.remove("offline");
			status.changed = status.online == false;
			status.online = true;
		} else {
			document.body.classList.add("offline");
			status.changed = status.online == true;
			status.online = false;
		}

		for (const uid in statusChangeHandlers) {
			statusChangeHandlers[uid](status);
		}
	}

	window.addEventListener("offline", handleNetworkChange);
	window.addEventListener("online", handleNetworkChange);

	handleNetworkChange({});
});

export default {
	API_ROOT: process.env.VUE_APP_API_ROOT,
	groupingMethod: "by-sector",

	online() {
		return status.online;
	},

	offline() {
		return !status.online;
	},

	status() {
		return status;
	},

	registerStatusChangeHandler(uid, handler) {
		statusChangeHandlers[uid] = handler;
	},

	revokeStatusChangedHandler(uid) {
		delete statusChangeHandlers[uid];
	},

	onOnline(uid, handler) {
		this.registerStatusChangeHandler(uid, status => {
			if (status.online) {
				handler(status);
			}
		});
	},

	onOffline(uid, handler) {
		this.registerStatusChangeHandler(uid, status => {
			if (!status.online) {
				handler(status);
			}
		});
	},

	async pingServer() {
		return await axios.options(this.API_ROOT);
	},

	async getRandomDadJoke() {
		return await axios
			.get("https://icanhazdadjoke.com/", {
				headers: {
					Accept: "application/json"
				}
			})
			.catch(() => {
				"Im Moment nicht verfügbar";
			});
	},

	async getAllSegmentsMinimal() {
		return await axios.get(`${this.API_ROOT}/segments/minimal`);
	},

	async getAllStreets() {
		return await axios.get(`${this.API_ROOT}/streets`);
	},

	setGroupingMethod(value) {
		this.groupingMethod = value;
	},

	getPrintVersionUrl(generateQrCodes) {
		const qrCodeQuery = generateQrCodes ? "&qr_codes=true" : "";
		return `${this.API_ROOT}/delivery-lists/${this.groupingMethod}/html?year=${year}` + qrCodeQuery;
	},

	async recordOrder(order, skipAddressCheck) {
		const endpoint = `${this.API_ROOT}/orders`;
		const params = skipAddressCheck ? { skipAddressCheck } : null;
		return await axios.post(endpoint, order, { params });
	},

	async getOrder(oid) {
		return await axios.get(`${this.API_ROOT}/orders/${oid}`);
	},

	async editOrder(oid, updateData, skipAddressCheck) {
		const endpoint = `${this.API_ROOT}/orders/${oid}`;
		const params = skipAddressCheck ? { skipAddressCheck } : null;
		return await axios.put(endpoint, updateData, { params });
	},

	async deleteOrder(oid) {
		return await axios.delete(`${this.API_ROOT}/orders/${oid}`);
	},

	async getAllOrders() {
		return await axios.get(`${this.API_ROOT}/orders`, { params: { year } });
	},

	async getAllDeliveryLists() {
		return await axios.get(`${this.API_ROOT}/delivery-lists/${this.groupingMethod}/populated`, {
			params: { year }
		});
	},

	async getDeliveryList(id) {
		return await axios.get(`${this.API_ROOT}/delivery-lists/${this.groupingMethod}/${id}`, {
			params: { year }
		});
	},

	async getDeliveryListEmbed(id) {
		return await axios.get(`${this.API_ROOT}/delivery-lists/by-sector/${id}/embed`, {
			params: { year }
		});
	},

	/**
	 * Generates and downloads a PDF version of this element
	 *
	 * @param {HTMLElement} htmlElement
	 * @param {Object} list
	 */
	async createPdf(htmlElement, list) {
		const filename = (list.id + " " + list.descriptive_name)
			.replace(/ /g, "_")
			.replace(/[,./\\?!@#%*+=^(){}[\]]/g, "");

		const pdf = require("html2pdf.js");

		pdf()
			.set({
				margin: 10,
				html2canvas: {
					scale: 4
				}
			})
			.from(htmlElement)
			.save(filename);
	},

	/**
	 * Creates a new author
	 *
	 * @param {string} username
	 */
	async createAuthor(username) {
		return await axios.post(`${this.API_ROOT}/authors`, { username });
	},

	/**
	 * Gets an author by ID
	 *
	 * @param {number} id
	 */
	async getAuthor(id) {
		return await axios.get(`${this.API_ROOT}/authors/${id}`);
	},

	/**
	 * Gets all authors
	 */
	async getAuthors() {
		return await axios.get(`${this.API_ROOT}/authors`);
	},

	async getPublicAuthors() {
		return await cacheFirst.get(`${this.API_ROOT}/authors/public`, {
			params: { orderBy: "username" }
		});
	},

	async getAdminAuthors() {
		return await axios.get(`${this.API_ROOT}/authors/admin`, {
			params: { orderBy: "username" }
		});
	},

	/**
	 * Gets all order lists
	 */
	async getOrderLists(authorId = null, cityNameId = null) {
		const params = { year };
		if (authorId !== globalAuthor.id) {
			params["authorId"] = authorId;
		}
		if (cityNameId) {
			params["cityNameId"] = cityNameId;
		}
		return await axios.get(`${this.API_ROOT}/order-lists`, { params });
	},

	/**
	 * Gets all permissions for all authors
	 */
	async getPublicListPermissions() {
		return await axios.get(`${this.API_ROOT}/public-list-permissions`, { params: { year } });
	},

	async getUnmatchedPublicLists() {
		return await axios.get(`${this.API_ROOT}/public-list-permissions/unmatched`, {
			params: { year }
		});
	},

	async addListPermission(authorId, listId) {
		return await axios.post(`${this.API_ROOT}/public-list-permissions`, {
			author_id: authorId,
			order_list_id: listId
		});
	},

	async deleteListPermission(authorId, listId) {
		return await axios.delete(`${this.API_ROOT}/public-list-permissions/${authorId}/${listId}`);
	},

	/**
	 * Gets user's order lists if logged in
	 */
	async getPublicOrderLists() {
		return await cacheFirst.get(`${this.API_ROOT}/order-lists/public`, {
			withCredentials: true,
			params: { year }
		});
	},

	/**
	 * Gets a single order list by id
	 *
	 * @param {number} id
	 */
	async getOrderList(id, useCache = false) {
		return await (useCache ? cacheFirst : axios).get(`${this.API_ROOT}/order-lists/${id}`);
	},

	/**
	 * Gets all orders in a given order list
	 *
	 * @param {number} olid
	 */
	async getOrderListOrders(olid, useCache = false) {
		return await (useCache ? cacheFirst : axios).get(`${this.API_ROOT}/order-lists/${olid}/orders`);
	},

	/**
	 * Creates an order list and returns the newly created entry
	 *
	 * @param {number} authorId Must be a valid (existing) author ID
	 * @param {string} sectorNameId Must be a valid (existing) name ID for a sector
	 */
	async createOrderList(authorId, sectorNameId) {
		return await axios.post(`${this.API_ROOT}/order-lists`, {
			author: authorId,
			sector: sectorNameId,
			year
		});
	},

	/**
	 * Updates an order list
	 *
	 * @param {number} listId
	 * @param {number} authorId
	 * @param {string} sectorNameId
	 */
	async updateOrderList(listId, authorId, sectorNameId) {
		const data = {};
		if (authorId) {
			data["author"] = authorId;
		}
		if (sectorNameId) {
			data["sector"] = sectorNameId;
		}
		return await axios.put(`${this.API_ROOT}/order-lists/${listId}`, data);
	},

	/**
	 * Deletes an order list permanently
	 *
	 * @param {number} listId
	 */
	async deleteOrderList(listId) {
		return await axios.delete(`${this.API_ROOT}/order-lists/${listId}`);
	},

	/**
	 * Gets all cities
	 */
	async getCities() {
		return await cacheFirst.get(`${this.API_ROOT}/cities`);
	},

	/**
	 * Gets all time slots
	 */
	async getTimeSlots() {
		return await cacheFirst.get(`${this.API_ROOT}/time-slots`);
	},

	/**
	 * Gets all sectors
	 */
	async getSectors() {
		return await axios.get(`${this.API_ROOT}/sectors`);
	},

	/**
	 * Tries to log in a user
	 */
	async login(user, pass) {
		return await axios.post(
			`${this.API_ROOT}/auth/login`,
			{
				user,
				pass
			},
			{ withCredentials: true }
		);
	},

	/**
	 * Stages a single order for delivery
	 *
	 * This will perform checks on the order, and if they all pass, the order will be published.
	 * Otherwise the order will be set to the 'CHECK' state for manual review.
	 *
	 * @param {object} order
	 * @param {boolean} noPublish
	 * @param {boolean} ignoreAddressNotFound
	 *
	 * @returns {Promise<AxiosResponse<{
	 * 	type: string,
	 * 	localId: string,
	 * 	duplicateOf?: number,
	 * 	message: string,
	 * 	order?: Object
	 * }>>}
	 */
	async stageOrder(order, noPublish = false, ignoreAddressNotFound = false) {
		return await axios.post(`${this.API_ROOT}/orders/stage`, order, {
			params: {
				no_publish: noPublish,
				ignore_address_not_found: ignoreAddressNotFound
			}
		});
	},

	/**
	 * Stages an array of orders for delivery.
	 *
	 * This Promise will never be rejected. Instead, an object with type field 'error' will be returned
	 *
	 * @see stageOrder for more details
	 *
	 * @param {Object[]} orders
	 * @param {boolean} noPublish
	 * @param {boolean} ignoreAddressNotFound
	 *
	 * @returns {Promise<[
	 * 	{type: 'new' | 'duplicate', localId: string, duplicateOf?: number, message: string, order?: Object}[],
	 * 	{type: 'error', message: string, localId: string}[]
	 * ]>} Two arrays, the first containing successful responses and second containing failed responses
	 */
	async stageOrders(orders, noPublish = false, ignoreAddressNotFound = false) {
		// Run requests in parallel, catch any errors and report back
		const requests = orders.map(order =>
			this.stageOrder(order, noPublish, ignoreAddressNotFound)
				.then(x => x.data)
				.catch(err => ({
					type: UPLOAD_TYPE_ERROR,
					message: err.response.data.errmsg,
					localId: order.localId
				}))
		);

		const results = await Promise.all(requests);
		/**
		 * @type {{type: 'new' | 'duplicate', localId: string, duplicateOf?: number, message: string, order?: Object}[]}
		 */
		const successes = results.filter(
			x => x.type === UPLOAD_TYPE_NEW || x.type === UPLOAD_TYPE_DUPLICATE
		);
		/**
		 * @type {{type: 'error', message: string, localId: string}[]}
		 */
		const errors = results.filter(x => x.type === UPLOAD_TYPE_ERROR);

		return [successes, errors];
	},

	async validateAdminPin(pin) {
		return await axios
			.post(`${this.API_ROOT}/auth/validate-admin-pin`, { pin })
			.then(x => x.data.authorized);
	},

	async getYears() {
		return await axios.get(`${this.API_ROOT}/years`).then(x => x.data.map(x => x.year));
	},

	async publishOrder(orderId) {
		return await axios.put(`${this.API_ROOT}/orders/publish/${orderId}`);
	},

	async unpublishOrder(orderId) {
		return await axios.put(`${this.API_ROOT}/orders/unpublish/${orderId}`);
	},

	/**
	 * Searches all orders using the search endpoint
	 *
	 * @param {{[key: string]: string|number|boolean}} filters List of filters to apply (exact, equality)
	 *
	 * Can consist of any valid order attribute and a matching value
	 *
	 * @param {string[]} sort List of sorting priorities, including optionally sorting direction ASC or DESC
	 *
	 * Can be any list of order attribute names
	 *
	 * @param {string} searchTerm Search query term to use. Ignored if null or empty
	 *
	 * @param {number} page Page to retrieve (out of range page will result in wrong result)
	 *
	 * @param {number} itemsPerPage How many items to include on one page
	 *
	 * @param {[number, string][]} cached List of [id, updated_at] for all cached orders
	 *
	 * @returns {Promise<AxiosResponse<{result: number[], total: number, pages: number, currentPageCount: number, new: Object[], modified: Object[], deleted: number[]}>>} Result set along with extra information about result
	 */
	async searchOrders(
		filters = {},
		sort = ["created_at DESC"],
		searchTerm = null,
		page = 0,
		itemsPerPage = 25,
		cached = []
	) {
		return await axios.post(
			`${this.API_ROOT}/orders/search`,
			{
				filters,
				sort,
				searchTerm,
				page,
				itemsPerPage,
				cached
			},
			{ params: { year } }
		);
	},

	/**
	 * Get a list of all statuses available
	 *
	 * @returns {Promise<AxiosResponse<string[]>>}
	 */
	async getStatuses() {
		return await axios.get(`${this.API_ROOT}/statuses`).catch(() => {
			// Provide fallback statuses
			return {
				data: ["LOCAL", "CHECK", "DRAFT", "PUBLISHED"],
				status: 200,
				statusText: "Fallback Content"
			};
		});
	},

	/**
	 * Fetches some statistics about a given order list. Pass order list of 0 for global stats
	 *
	 * @param {number} orderListId ID of the list to fetch stats for, or 0 for all orders of this year
	 *
	 * @returns {Promise<AxiosResponse<{
	 * 	total_orders: number,
	 * 	total_small: number,
	 * 	total_large: number,
	 * 	orders_8_9: number,
	 * 	orders_9_10: number,
	 * 	orders_10_11: number,
	 * 	total_check: number,
	 * 	total_draft: number
	 * }>>} Stats about the order list
	 */
	async getListStats(orderListId = globalOrderList.id) {
		return await axios.get(`${this.API_ROOT}/orders/stats`, {
			params: { order_list: orderListId, year }
		});
	},

	/**
	 * Gets geographical information about a location
	 *
	 * @param {string} searchText Location to search for (city, street, building, ...)
	 *
	 * @returns {Promise<AxiosResponse<{
	 * 	fuzzy?: string|undefined,
	 * 	results: {
	 * 		id: number,
	 * 		weight: number,
	 * 		attrs: {
	 * 			origin: string,
	 * 			geom_quadindex: string,
	 * 			zoomlevel: number,
	 * 			featureId: string,
	 * 			detail: string,
	 * 			rank: number,
	 * 			num: number,
	 * 			label: string
	 * 		}
	 * 	}[]
	 * }>>} Map Geo Admin results
	 */
	async getLocationInfo(searchText) {
		return axios.get(`${this.API_ROOT}/location-info`, { params: { searchText } });
	}
};
